<template>
  <div>
    <label v-if="label" class="text-xs pl-3 text-left block mb-1">{{ label }} <span class="text-red-500" v-if="asterix">*</span></label>
    <div class="dropdown bg-white rounded-lg">
      <div class="selector" @click="toggle()" :class="{ 'border-grey-field border rounded-lg': borderEnabled }">
        <div :class="`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} px-3 py-3 w-full`">
          <span v-if="placeholderText" class="text-neutral-300">{{ placeholderText }}</span>
          <span v-else-if="optionLabel">{{ selected[optionLabel] }}</span>
          <span class="select-label" v-else>{{ selected }}</span>
        </div>
        <span class="drop-arrow absolute right-3 top-1/2 transform -translate-y-1/2 leading-0" :class="{ expanded: visible }">
          <Chevron direction="down" width="14" height="14" :color="disabled ? '#D6D6D6' : '#3b82f6'" />
        </span>
        <div :class="{ hidden: !visible, visible }" class="content rounded-lg shadow-soft">
          <ul class="options">
            <li
              class="p-3 text-neutral hover:bg-yellow text-sm hover:text-white cursor-pointer transition-all duration-200"
              :class="{
                'bg-yellow-secondary': item === selected && !item[optionDisableItem] | false,
                'bg-neutral-300 hover:bg-neutral-300 hover:text-neutral cursor-not-allowed': item[optionDisableItem] | false
              }"
              v-for="(item, index) in options"
              @click="select(item)"
              :key="'item-' + index"
            >
              <span v-if="optionLabel">{{ item[optionLabel] }}</span>
              <span v-else>{{ item }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="importantNotes && notes" class="important-notes text-xs leading-tight inline-block p-2">{{ notes }}</div>
    <span v-if="notes" class="text-xs text-gray-500 mt-1 leading-tight inline-block">{{ notes }}</span>
    <transition name="fade" mode="out-in">
      <div class="text-system-error text-left p-2 text-xs" v-if="error">{{ error }}</div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'aselect',
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    asterix: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabel: {
      type: String,
      default: null
    },
    optionDisableItem: {
      type: String,
      default: null
    },
    default: {
      required: false,
      default: null
    },
    borderEnabled: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Pilih'
    },
    error: {
      type: String,
      default: ''
    },
    notes: {
      type: String,
      default: ''
    },
    importantNotes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: this.default ? this.default : '',
      visible: false,
      placeholderText: this.default ? null : this.placeholder
    }
  },
  watch: {
    default(val) {
      this.selected = val
      if (val) {
        this.placeholderText = null
      }
    }
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.visible = !this.visible
        this.resetScroll()
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.visible = false
        this.resetScroll()
      }
    },
    resetScroll() {
      const el = document.querySelector('.content')
      el.scrollTop = 0
    },
    select(option) {
      if (option[this.optionDisableItem]) return
      this.selected = option
      this.placeholderText = null
      this.$emit('input', option)
    }
  },
  mounted() {
    this.$emit('input', this.selected)
    document.addEventListener('click', this.close)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  }
}
</script>
<style lang="scss" scoped>
.dropdown {
  width: 100%;
  .selector {
    position: relative;
    z-index: 1;
    .drop-arrow {
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
    }
    .expanded {
      transform: rotateZ(180deg) translateY(7px);
    }
  }
  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .content {
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: rgba($color: #fff, $alpha: 1);
  }
}

.important-notes {
  margin-top: 10px;
  background: #fffaf5;
  position: relative;
  cursor: pointer;
  border: 1px solid #fde9d2;
  border-radius: 10px;
}
.important-notes:before {
  content: '';
  position: absolute;
  bottom: -webkit-calc(100% - 4px);
  bottom: calc(100% - 4px);
  left: 13px;
  height: 10px;
  width: 10px;
  background: #fffaf5;
  transform: rotate(45deg);
  border-top: inherit;
  border-left: inherit;
  box-shadow: inherit;
}
</style>
